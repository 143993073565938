define(['workbox'], (workbox) => {
  if ('serviceWorker' in navigator) {
    const wb = new workbox.Workbox('/sw.js');

    wb.addEventListener('installed', event => {
      if (event.isUpdate) {
        window.location.reload();
      }
    });

    wb.register();
  }
});
